<template>
    <div class="verification-documents">
        <div class="verification-documents__header">
            <h3 class="verification-documents__header-title">Запрашиваемые документы</h3>

            <p class="verification-documents__header-subtitle">Укажите названия документа (ов) для предоставления
                кандидатом</p>
        </div>

        <div class="verification-documents__documents">
            <div class="verification-documents__documents-header">
                <div class="verification-documents__documents-header-row">
                </div>
            </div>

            <div>
                <div class="verification-documents__documents-items">
                    <VDocumentTemplate v-for="(document, index) in form.documents" :key="index" :name="document.title"
                        :required="document.is_required" :index="1" @remove="deleteDocument(index)"
                        @edit="editDocument(document, index)" class="verification-documents__documents-item" />
                </div>

                <div @click="showPopup = true" class="verification-documents__documents-btn">
                    <img src="@/assets/images/icons/plus-blue.svg" alt="plus">
                    Добавить документ
                </div>

                <div class="validation-error verification-documents__error" v-if="error">Добавьте документы</div>
            </div>
        </div>
        <div v-if="companyAccess.access_extended_form" class="verification-documents__questionnaire">
            <div class="verification-documents__questionnaire-header">
                <div class="verification-documents__questionnaire-title">Анкета кандидата</div>
            </div>

            <div class="verification-documents__questionnaire-content">
                <div style="width: 100%;">
                    <VSelect placeholder="Выберите шаблон анкеты (опционально)" :options="extendedFormFilter"
                        labelFild="extended_form_name" v-model="form.extendedFormId" :id="8" style="width: 100%;" />
                </div>

                <div v-if="form.extendedFormId" @click="viewQuestionnaire"
                    class="verification-documents__questionnaire-content-btn">
                    Посмотреть анкету
                </div>
            </div>
        </div>

        <ModalWindow v-if="showPopup" @close="closeModal" class="verification-documents__modal">
            <AddDocument :editDoc="editDoc" @close="closeModal" @add="addDocument" />
        </ModalWindow>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import VDocumentTemplate from '@/components/UI/VDocumentTemplate'
import AddDocument from "@/components/ModalWindow/ModalBodyes/AddDocument";

const props = defineProps({
    modelValue: Object,
    extendedForm: {
        type: Array,
    },
    companyAccess: {
        type: Object
    },
    error: {
        type: Boolean,
        default: false
    },
    vacancyId: {
        type: Number
    }
})

const emit = defineEmits(["update:modelValue", "add"]);
const router = useRouter()

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});
const extendedFormFilter = computed(() => {
    const arr = []

    for (let key in props.extendedForm) {
        arr.push(props.extendedForm[key])
    }

    return arr
})

const openResponsible = ref(false)

const extendedQuestionnaire = ref(false)
const templateExtendedQuestionnaire = ref(null)
const showPopup = ref(false)

const optionsTemplateExtendedQuestionnaire = [
    {
        id: 1,
        label: 'Анкета ИТР состава'
    },
    {
        id: 2,
        label: 'Анкета проф подбора'
    },
    {
        id: 3,
        label: 'Анкета массового подбора'
    },
]

const editDocIndex = ref(null)
const editDoc = ref(null)

const addDocument = (data) => {
    if (!editDoc.value) {
        if (!form.value.documents) form.value.documents = []
        form.value.documents.push({
            title: data.name,
            description: data.description,
            is_required: data.required,
        });
        showPopup.value = false
        editDoc.value = null
        editDocIndex.value = null
    } else {
        form.value.documents[editDocIndex.value].title = data.name
        form.value.documents[editDocIndex.value].description = data.description
        form.value.documents[editDocIndex.value].is_required = data.required

        showPopup.value = false
        editDoc.value = null
        editDocIndex.value = null
    }
    emit('add')
};

const deleteDocument = (index) => {
    form.value.documents.splice(index, 1)
}

const editDocument = (doc, index) => {
    editDoc.value = doc
    editDocIndex.value = index
    showPopup.value = true
}

const closeModal = () => {
    showPopup.value = false
    editDoc.value = null
    editDocIndex.value = null
}

const extendedQuestionnaireRequired = (val) => {
    if (!extendedQuestionnaire.value) {
        return true
    } else {
        return !!val
    }
}

const rules = computed(() => ({
}));

const v$ = useVuelidate(rules, form);

const viewQuestionnaire = () => {
    router.push({ path: '/extended-questionnaire', query: { id: props.vacancyId, pageType: 'create' } })
}
</script>

<style scoped lang="scss">
.verification-documents {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

    &__error {
        margin-top: 8px;
        color: $red;
        font-size: 14px;

        &_select {
            margin-top: 4px;
        }
    }


    &__header-title {
        color: $dark;
        font-size: 20px;
        font-weight: 500;
    }

    &__header-subtitle {
        margin-top: 16px;
        color: #8F9CAC;
        font-size: 14px;
    }

    &__select-search {
        width: 100%;
        margin-top: 20px;
    }

    &__select-search-header {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light;
        cursor: pointer;
        user-select: none;

        img {
            width: 20px;
            transition: .3s;
        }

        &_active {
            img {
                transform: rotate(-180deg);
            }
        }
    }

    &__select-search-header-title {
        color: $dark-blue;
        font-size: 14px;
        font-weight: 400;
    }

    &__select-search-open {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid $light-gray-1;
        background-color: #fff;
    }

    &__select-search-field {
        width: 100%;
        height: 40px;
        padding: 0 12px;
        border-radius: 12px;
        border: 1px solid $light-gray-1;
        display: flex;
        align-items: center;

        input {
            width: calc(100% - 36px);
            margin-left: 8px;
        }

        img {
            width: 16px;
        }
    }

    &__select-search-options {
        width: 100%;
        margin-top: 4px;
    }

    &__select-search-option {
        width: 100%;
        height: 40px;
        padding-left: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        cursor: pointer;
        transition: .3s;

        &:hover {
            background-color: $light-gray-1;
        }
    }

    &__documents {
        margin-top: 20px;
    }

    &__documents-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__documents-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__documents-header-row {
        display: flex;
        align-items: center;
    }

    &__documents-text {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $light;
        color: $dark-blue;
        font-size: 14px;
        font-weight: 400;
    }

    &__documents-change-btn {
        margin-left: 12px;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        user-select: none;
        cursor: pointer;
    }

    &__documents-content {
        margin-top: 16px;
    }

    &__documents-items {
        margin-top: -12px;
        margin-bottom: 16px;
        margin-left: -12px;
        display: flex;
        flex-wrap: wrap;
    }

    &__documents-item {
        width: 187px;
        margin-left: 12px;
        margin-top: 12px;
    }

    &__documents-btn {
        width: 187px;
        height: 56px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5F8FF;
        color: $blue;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;

        img {
            width: 24px;
            margin-right: 8px;
        }
    }

    &__questionnaire {
        margin-top: 20px;
    }

    &__questionnaire-header {
        padding-top: 24px;
        border-top: 1px solid $light-gray-1;
    }

    &__questionnaire-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__questionnaire-subtitle {
        margin-top: 4px;
        color: #8F9CAC;
        font-size: 14px;
    }

    &__questionnaire-content {
        margin-top: 16px;
        display: flex;
        align-items: center;
    }

    &__questionnaire-content-btn {
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        user-select: none;
        cursor: pointer;
        white-space: nowrap;
        margin-left: 16px;
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 514px;
        }
    }
}
</style>
