import api from "./api";

const specialties = {
    getSpecialties(query) {
        return api.get('/specialities', { params: query});
    },
    getSearchSpecialties(query) {
        return api.get('/specialities/search', { params: query});
    },
    postSpecialty(body) {
        return api.post('/specialities', body);
    },
    putSpecialty(id, body) {
        return api.put(`/specialities/${id}`, body);
    },
    deleteSpecialty(id) {
        return api.delete(`/specialities/${id}`);
    }
};

export default specialties;
