<template>
  <div class="modal-body">
    <div class="modal-body__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close" />
    </div>

    <h3 v-if="!editDoc" class="modal-body__title">Добавление документа</h3>
    <h3 v-else class="modal-body__title">Редактирование документа</h3>

    <div class="modal-body__form">
      <VCustomInput
        class="modal-body__inp"
        placeholder="Название документа*"
        :maxlength="40"
        :error="error && !form.name"
        v-model="form.name"
      />
      <p class="modal-body__error" v-if="error && !form.name">Заполните обязательное поле</p>
<!--      <VCustomInput-->
<!--        class="modal-body__inp"-->
<!--        placeholder="Описание документа"-->
<!--        :maxlength="80"-->
<!--        v-model="form.description"-->
<!--      />-->

        <VTextarea
            class="modal-body__textarea"
            placeholder="Описание документа"
            :maxlength="200"
            :preventCondition="form.description?.length > 200"
            v-model="form.description"
        />

      <div class="modal-body__form-checkbox">
        <p class="modal-body__form-checkbox-label">Обязательность документа</p>
        <VCheckbox v-model="form.required" />
      </div>

      <VButton v-if="!editDoc" class="modal-body__btn" clickEffect label="Добавить документ" @click="submit" />
      <VButton v-else class="modal-body__btn" clickEffect label="Сохранить изменения" @click="submit" />
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted} from "vue";

  const props = defineProps({
      editDoc: {
          type: Object
      }
  })

  const form = reactive({
    name: "",
    description: "",
    required: false,
  });

  const emit = defineEmits(["add"]);

  const error = ref(false);

  const submit = () => {
    if (!form.name) {
      error.value = true;
      return;
    }

    emit('add', form)
  };

  onMounted(() => {
      if(props.editDoc) {
          form.name = props.editDoc.title
          form.description = props.editDoc.description
          form.required = props.editDoc.is_required
      }
  })
</script>

<style scoped lang="scss">
  .modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    &__title {
      color: $dark;
      font-size: 20px;
      font-weight: 600;
    }

    &__form {
      width: 100%;
      margin-top: 16px;
    }

    &__inp {
      width: 100%;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

      &__textarea {
          width: 100%;
          height: 130px;
          margin-top: 12px;
          border: none;

          &::v-deep(.v-textarea__placeholder) {
              color: $dark-blue !important;
          }
      }

    &__error {
      text-align: left;
      margin-top: 4px;
      font-size: 14px;
      color: $red;
    }

    &__form-checkbox {
      margin-top: 17px;
      padding-right: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__form-checkbox-label {
      font-size: 14px;
    }

    &__btn {
      width: 100%;
      margin-top: 29px;
    }
  }
</style>
