<template>
  <div class="add-responsible">
    <div class="add-responsible__close">
      <img src="@/assets/images/icons/x.svg" alt="close" @click="clickCancel" />
    </div>
    <div class="add-responsible__head">
      <h3 class="add-responsible__title">{{ data.title }}</h3>
      <p class="add-responsible__subtitle">{{ data.subtitle }}</p>
    </div>
    <div class="add-responsible__main">
      <div class="add-responsible__search">
        <span class="add-responsible__search-icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
              stroke="#566A83" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
        <input class="add-responsible__search-input" type="text" placeholder="Введите ФИО или должность" @input="search"
          v-model="seacrhInput" />
        <div class="add-responsible__list" v-if="employees && data.type === 'epmloyees'">
          <label class="add-responsible__list-item" v-for="employee in filteredEmployees" :key="employee.id"
            @click="selectEmployee(employee.id)">

            <input class="add-responsible__list-item-checkbox" type="checkbox" :value="employee"
              v-model="selectedEmployees" /><span class="add-responsible__list-item-text">{{ employee.full_name
              }}</span>
          </label>
        </div>
        <div class="add-responsible__list" v-else-if="employees && data.type !== 'epmloyees'">
          <label class="add-responsible__list-item" v-for="employee in filteredEmployees" :key="employee.id"
            @click="selectResponsable(employee.id)">
            <input class="add-responsible__list-item-radio" type="radio" :value="employee"
              v-model="selectedEmployees" /><span class="add-responsible__list-item-name">{{ employee.full_name
              }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="add-responsible__footer">
      <VButton class="add-responsible__submit-btn" label="Добавить" @click="addResponsibleSubmit" />
      <VButton class="add-responsible__cancel-btn" label="Отменить" @click="clickCancel" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

const props = defineProps({
  employees: {
    type: Array
  },
  selectedEmployeesList: {
    type: Array
  },
  data: {
    type: Object
  }
})

const emit = defineEmits(["update", "add", "close", "cancel", "addEmployees", "addResponsible"]);
const seacrhInput = ref('')
const selectedEmployees = ref([])
const filteredEmployees = computed(() => props.employees.filter(employee => employee.full_name.toLowerCase().includes(seacrhInput.value.toLowerCase())))
const search = () => {

}
const selectEmployee = (id) => {
  let finded = props.employees.find((employee) => employee.id === id)
}

const clickCancel = () => {
  emit('cancel', props.data.type)
}
const selectResponsable = (id) => {

}
const addResponsibleSubmit = () => {
  if (props.data.type === 'epmloyees') {
    if (!selectedEmployees.value.length) return;
    emit("addEmployees", selectedEmployees.value)
  } else {
    if (!selectedEmployees.value?.id) return;
    emit("addResponsible", { ...selectedEmployees.value, type: props.data.type })
  }
  emit("close")
}

watch(selectedEmployees, () => {
  emit('update', selectedEmployees.value)
}
)
</script>

<style lang="scss" scoped>
.add-responsible {
  position: relative;
  padding: 28.5px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;

  }

  &__subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;

  }

  &__main {
    flex: 1 1 auto;
    width: 100%;
  }

  &__search {
    position: relative;
    margin-bottom: 8px;
  }

  &__search-icon {
    position: absolute;
    left: 12px;
    top: 12px;
  }


  &__search-input {
    width: 100%;
    height: 40px;
    padding-left: 36px;
    border: 1px solid $light-gray-1;
    border-radius: 12px;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;


    &::placeholder {
      color: $dark-blue;
      opacity: 1;
    }
  }

  &__footer {
    display: flex;
    gap: 12px;
    width: 100%;

    &>div {
      flex: 1 1 100%;
    }
  }

  &__submit-btn {}

  &__cancel-btn {
    background-color: $light-gray-1 !important;
    color: $dark !important;
  }

  &__list {
    margin-top: 8px;
    height: 216px;
    overflow-y: auto;
    scrollbar-width: thin;

  }

  &__list-item {
    display: flex;
    padding: 10px 8px;
  }

  &__list-item-checkbox {
    position: relative;
    flex-shrink: 0;
    appearance: none;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray-2;
    border-radius: 4px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 2px;
      top: 1px;
      width: 14px;
      height: 14px;
      transform: scale(0);
      background-image: url('../../../../assets/images/icons/check.svg');
      background-size: contain;
    }

    &:checked {
      border-color: $blue;
    }


    &:checked::before {
      transform: scale(1);
    }
  }

  &__list-item-radio {
    position: relative;
    flex-shrink: 0;
    appearance: none;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray-2;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border: 3px solid #fff;
      background-color: $blue;
      border-radius: 50%;
      transform: scale(0);
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &__list-item-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }

  &__list-item-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }
}
</style>