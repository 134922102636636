<template>
    <div class="buying-tickets">
        <div :class="['buying-tickets__header', { 'bottom-border_show': form.availableRouteMemo }]">
            <div class="buying-tickets__title">Маршрутная памятка</div>

            <VCheckbox v-model="form.availableRouteMemo" />
        </div>

        <div v-if="form.availableRouteMemo" class="buying-tickets__route-memo">
            <VEditor v-model="form.buyingTicketsDescription" :error="v$.buyingTicketsDescription?.$error"
                placeholder="Памятка к маршруту*" class="buying-tickets__route-memo-editor" />
            <p v-if="v$.buyingTicketsDescription?.$error" class="buying-tickets__inp-error">Заполните обязательное
                поле</p>
        </div>

        <div v-if="form.availableRouteMemo" class="buying-tickets__images">
            <div class="buying-tickets__images-title">Фотографии места прибытия</div>
            <div class="buying-tickets__images-list">
                <VFileImage v-for="(file, index) in buyingTickets.buying_tickets_files" :key="index" :file="file"
                    deleteBtn @delete="deleteFile(index)"
                    class="buying-tickets__images-list-item buying-tickets__images-list-file" />
                <div class="buying-tickets__images-list-item buying-tickets__images-upload">
                    <input @change="changeFile" type="file">
                    <img src="@/assets/images/icons/upload.svg" alt="upload">
                    Загрузить файл
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import VEditor from "@/components/UI/VEditor";
import vacanciesApi from "@/api/vacancies";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const props = defineProps({
    modelValue: Object,
    employees: {
        type: Array,
    },
    vacancyId: {
        type: [String, Number]
    },
    buyingTickets: {
        type: Object
    }
})

const emit = defineEmits(["update:modelValue", "getFields"]);

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const buyingTicketsDescriptionRequired = (val) => {

    if (!form.value.availableRouteMemo) {
        return true
    } else {
        return !!val
    }
}

const availableRouteMemoRequired = () => {
    if (!form.value.availableRouteMemo) {
        return true
    } else {
        return !!val
    }
}

const buyingTicketsRequired = (val) => {
    if (!form.value.buyingTickets) {
        return true
    } else {
        return !!val.length
    }
}

const rules = computed(() => ({
    buyingTicketsDescription: { buyingTicketsDescriptionRequired },
}));

const v$ = useVuelidate(rules, form);

const files = ref([])
const deleteFile = (index) => {
    deleteFileRequest(props.buyingTickets.buying_tickets_files[index]?.id)
    props.buyingTickets.buying_tickets_files.splice(index, 1)
}

const maxImageSize = 10000000

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0';
    } else {
        let k = 1024;
        let dm = decimals < 0 ? 0 : decimals;
        let sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        let i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
const changeFile = async (e) => {
    if (e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') {
        return
    }


    if (e.target.files[0].size < maxImageSize) {
        const formData = new FormData();
        formData.append('buying_tickets_files[]', e.target.files[0]);

        await sendFile(formData)
        emit('getFields')
    }
}

const sendFile = async (body) => {
    try {
        const response = await vacanciesApi.sendFile(props.vacancyId, body)
    } catch (error) {
        console.log(error)
    }
}

const deleteFileRequest = async (id) => {
    try {
        const response = await vacanciesApi.deleteFile(props.vacancyId, id)
    } catch (error) {
        console.log(error)
    }
}

onMounted(() => {
    if (form.value.buyingTicketsDescription || props.buyingTickets.buying_tickets_files?.length) {
        form.value.availableRouteMemo = true
    }
})
</script>

<style scoped lang="scss">
.buying-tickets {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__select {
        width: 100%;
        margin-top: 20px;
    }

    &__images {
        padding-top: 20px;
    }

    &__images-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__images-list {
        margin-top: 4px;
        margin-left: -12px;
        display: flex;
        flex-wrap: wrap;
    }

    &__images-upload {
        position: relative;
        width: 187px;
        height: 56px;
        padding: 0 23.5px;
        border-radius: 8px;
        background-color: $primary-blue;
        color: $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        img {
            width: 24px;
            margin-right: 8px;
        }

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__images-list-file {
        width: 187px;
    }

    &__images-list-item {
        margin-left: 12px;
        margin-top: 12px;
    }

    &__route-memo {
        width: 100%;
        margin-top: 20px;
    }

    &__route-memo-header {
        border-top: 1px solid $light-gray-1;
        width: 100%;
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__route-memo-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__route-memo-editor {
        width: 100%;
        height: 220px;
        margin-top: 20px;
    }

    &__inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: #DD4646;

        &_images {
            margin-top: 12px;
        }
    }
}

.bottom-border {
    &_show {
        padding-bottom: 20px;
        border-bottom: 1px solid $light-gray-1;
    }
}
</style>
